import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import FigmaEmbed from 'components/FigmaEmbed';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}</h2>
    <p>{`Use a datepicker when a user has to select a single date or date range from a
grid style calendar or by typing in a date corresponding to the placeholder.`}</p>
    <h2 {...{
      "id": "behaviour",
      "style": {
        "position": "relative"
      }
    }}>{`Behaviour`}</h2>
    <p>{`Clicking the calendar icon triggers the calendar dialog. Within the dialog, the
current date is indicated by an active colored state. Switching between months
can be done by toggleing the previous or next arrow.`}</p>
    <p>{`Selecting a single date can also be done by typing in the date as indicated by
the placeholder text.`}</p>
    <h3 {...{
      "id": "dekstop-datepicker",
      "style": {
        "position": "relative"
      }
    }}>{`Dekstop DatePicker`}</h3>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=646%3A333" mdxType="FigmaEmbed" />
    <h3 {...{
      "id": "mobile-datepicker",
      "style": {
        "position": "relative"
      }
    }}>{`Mobile DatePicker`}</h3>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=651%3A0" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "guidelines",
      "style": {
        "position": "relative"
      }
    }}>{`Guidelines`}</h2>
    <ul>
      <li parentName="ul">{`The calendar dialog is closed after a single date is selected.`}</li>
      <li parentName="ul">{`Provide obvious defaults and highlight commont selections.`}</li>
      <li parentName="ul">{`Follow agreed-upon date formats.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      